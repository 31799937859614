import logo from './logo.svg';
import Container from './Components/Container';
import './App.css';
import { Outlet } from 'react-router-dom';



function App() {

  return (
    
    <div className="mainBG">
      <Container />
    </div>
    
    );
}


export default App;
